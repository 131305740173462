import React, { useEffect, useState, CSSProperties } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  LineController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { IFilter } from "../../Interfaces/IFilter";
import { useService } from "../../Hooks/useService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  BarController,
  LineController,
  Title,
  Tooltip,
  Legend
);

const Dashboard: React.FC = () => {
  const { GetRadioDashboardService } = useService();
  const [dashboardData, setDashboardData] = useState<any>(null);

  const GetDashboard = async () => {
    try {
      const res: any = await GetRadioDashboardService();
      console.log("Resposta bruta da API:", res);

      if (res && res.result) {
        console.log("Dados processados:", res.result);
        setDashboardData(res.result);
      } else {
        console.warn("Nenhum dado encontrado na resposta da API.");
        setDashboardData({});
      }
    } catch (error) {
      console.error("Erro ao buscar dados do dashboard:", error);
      setDashboardData({});
    }
  };

  useEffect(() => {
    GetDashboard();
  }, []);

  const roundToTwo = (num: number) => {
    return isNaN(num) ? 0 : Math.round(num * 100) / 100;
  };

  const boxStyle: CSSProperties = {
    boxShadow: "0px 0px 5px 0px rgba(174, 174, 174, 0.6)",
    borderRadius: "5px",
    padding: "16px",
    marginRight: "16px",
    textAlign: "center" as const,
  };

  if (!dashboardData) {
    return <div>Carregando...</div>;
  }

  // Dados do gráfico
  const data = {
    labels: dashboardData.monthlyData
      ? dashboardData.monthlyData.map((monthData: any) => `${monthData.month}/${monthData.year}`)
      : [],
    datasets: [
      {
        type: "bar" as const,
        label: "Preço Médio Cotação Vencedora",
        data: dashboardData.monthlyData
          ? dashboardData.monthlyData.map((monthData: any) => parseFloat(monthData.priceSelect.replace(",", ".")))
          : [],
        backgroundColor: "rgba(0, 0, 255, 0.5)",
        borderColor: "blue",
        borderWidth: 1,
      },
      {
        type: "line" as const,
        label: "Seu Preço Médio",
        data: dashboardData.monthlyData
          ? dashboardData.monthlyData.map((monthData: any) => parseFloat(monthData.priceForn.replace(",", ".")))
          : [],
        fill: false,
        borderColor: "orange",
        tension: 0.1,
      },
    ],
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Cards */}
      <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "20px" }}>
        <div style={boxStyle}>
          <h1>
            <b>{dashboardData.globalData.countRideIDs}</b>
          </h1>
          <p>Cotações Realizadas</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>{dashboardData.globalData.totalRidesWithReceipts}</b>
          </h1>
          <p>Corridas Realizadas</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>{roundToTwo(dashboardData.globalData.porcentagemRidesWithReceipts)}%</b>
          </h1>
          <p>Percentual de Corridas Realizadas x Cotadas</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>R$ {dashboardData.globalData.avgPriceSelect}</b>
          </h1>
          <p>Preço Médio Cotação Vencedora</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>R$ {dashboardData.globalData.avgPriceForn}</b>
          </h1>
          <p>Seu Preço Médio</p>
        </div>
        <div style={boxStyle}>
          <h1>
            <b>{roundToTwo(dashboardData.globalData.variacaoPreco)}%</b>
          </h1>
          <p>Variação Percentual do Preço</p>
        </div>
      </div>

      {/* Gráfico */}
      <div
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    height: "500px",
    margin: "0 auto",
  }}
  >
  <Chart type="bar" data={data} options={{ responsive: true }} />
    </div>
    </div>
  );
};

export default Dashboard;
